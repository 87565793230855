import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import logo from './logo.svg';
import './style/main.scss';
import Home from "./components/Home";
import About from "./components/About";
import {CSSTransition, TransitionGroup} from "react-transition-group";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 600)
    }


   render() {
       const {isLoading} = this.state;
       const loadingScreen = isLoading ?
           <CSSTransition in={isLoading} appear={true} timeout={1000} classNames={'fade'}>
               <div className={'loading-screen'}>
                   <div className={'loading-screen__logo loading-screen__glitch'} data-text={"CRL"}>
                       <span>C</span>
                       <span>R</span>
                       <span>L</span>
                   </div>
               </div>
           </CSSTransition>
           : null;
       const loadedContent = !isLoading ?
           <CSSTransition in={!isLoading} appear={true} timeout={1001} classNames={'fade'}>
               <div className={'main__loader'}>
                   <Router>
                       {/*<div className={"navbar"}>
                       <Link className={"navbar__logo-link"} to={"/"}>
                           <div className={'navbar__logo'}>
                               CRL
                           </div>
                       </Link>
                       <ul className={"navbar__links"}>
                           <li className={"navbar__link-item"}>
                               <Link className={"navbar__link"} to="/about">
                                   About
                                   <span className={"navbar__dot-wrapper"}>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                            </span>
                               </Link>

                           </li>
                           <li className={"navbar__link-item"}>
                               <Link className={"navbar__link"} to="/portfolio">
                                   Portfolio
                                   <span className={"navbar__dot-wrapper"}>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                                <span className={"navbar__link-dot"}></span>
                            </span>
                               </Link>
                           </li>
                       </ul>
                   </div>*/}
                       <main className={"main"}>
                           <h1 className={'main__title loading-screen__glitch'} data-text={"CRLDESIGN"}>CRLDESIGN</h1>
                           <div className={'main__content'}>
                               <p>
                                   I'm a web developer, UXer, designer and general problem-solver. My core skill-set revolves around PHP-based CMSs, HTML5 accessibility, SCSS, and React, as you can probably see through inspect.
                               </p>
                               <p>
                                   Lately I've been really exploring clip-path, which is a css property I overlooked for a really long time. But its pretty cool and you can do some really neat stuff with it. In the past,
                                   I would have resorted to other means not well-suited to achieve similar results, like using svg animations and empty spans or what have you. The only catch is that apparently most browsers - webkit included - seem to have some
                                   issues computing background transitions for elements with clip-path and their children, which results in some crazy flicker issues. These go away with filter:blur(0px), for some reason, and its pretty neat to see that it's been a known bug for about a decade.
                                   Thanks stack overflow!
                               </p>
                               <p>
                                   I've designed language-assistive apps for the speech-impaired, built entire corporate websites from the ground up, wrangled CRMs and whatever else needs doing.
                               </p>
                               <ul className={'social__list'}>
                                   <li>
                                       <a href={'https://github.com/albanyacademy'} className={'social__link'} target={'_blank'} rel="noopener noreferrer">
                                           <svg height="20" className={'social__icon'} alt="GitHub"
                                                viewBox="0 0 16 16" version="1.1" width="20" aria-hidden="true">
                                               <path fill-rule="evenodd" fill="currentColor"
                                                     d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                                           </svg>
                                       </a>
                                   </li>
                                   <li>
                                       <a href={'https://www.linkedin.com/in/crlindsay/'} className={'social__link'} target={'_blank'} rel="noopener noreferrer">
                                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382 382" className={'social__icon'}><path d="M347.445 0H34.555C15.471 0 0 15.471 0 34.555v312.889C0 366.529 15.471 382 34.555 382h312.889C366.529 382 382 366.529 382 347.444V34.555C382 15.471 366.529 0 347.445 0zM118.207 329.844c0 5.554-4.502 10.056-10.056 10.056H65.345c-5.554 0-10.056-4.502-10.056-10.056V150.403c0-5.554 4.502-10.056 10.056-10.056h42.806c5.554 0 10.056 4.502 10.056 10.056v179.441zM86.748 123.432c-22.459 0-40.666-18.207-40.666-40.666S64.289 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.91 330.654a9.247 9.247 0 01-9.246 9.246H286.73a9.247 9.247 0 01-9.246-9.246v-84.168c0-12.556 3.683-55.021-32.813-55.021-28.309 0-34.051 29.066-35.204 42.11v97.079a9.246 9.246 0 01-9.246 9.246h-44.426a9.247 9.247 0 01-9.246-9.246V149.593a9.247 9.247 0 019.246-9.246h44.426a9.247 9.247 0 019.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.131 68.716 73.131 106.472v86.846z" fill="currentColor"/></svg>
                                       </a>
                                   </li>
                               </ul>
                           </div>
                           <Switch>
                               <Route path="/about">
                                   <About />
                               </Route>
                               <Route path="/portfolio">
                                   <Topics/>
                               </Route>
                               <Route path="/">

                               </Route>
                           </Switch>
                       </main>
                   </Router>
               </div>
           </CSSTransition>
           : null;
       return (
           <TransitionGroup className={'main-content-loader'}>
               {loadingScreen}
               {loadedContent}
           </TransitionGroup>
       );
   }
}

function Topics() {
    let match = useRouteMatch();

    return (
        <div>
            <h2>Topics</h2>

            <ul>
                <li>
                    <Link to={`${match.url}/components`}>Components</Link>
                </li>
                <li>
                    <Link to={`${match.url}/props-v-state`}>
                        Props v. State
                    </Link>
                </li>
            </ul>

            {/* The Topics page has its own <Switch> with more routes
          that build on the /topics URL path. You can think of the
          2nd <Route> here as an "index" page for all topics, or
          the page that is shown when no topic is selected */}
            <Switch>
                <Route path={`${match.path}/:topicId`}>
                    <Topic/>
                </Route>
                <Route path={match.path}>
                    <h3>Please select a topic.</h3>
                </Route>
            </Switch>
        </div>
    );
}

function Topic() {
    let {topicId} = useParams();
    return <h3>Requested topic ID: {topicId}</h3>;
}

export default App;
