import React, { Component } from 'react';

class About extends Component {

    render() {
        return (
            <div>
                <h1>About</h1>
                <div>
                    <p>
                        I'm a web developer, UXer, designer and general problem-solver. My core skill-set revolves around PHP-based CMSs, HTML5 accessibility, SCSS, and React, as you can probably see through inspect.
                    </p>
                    <p>
                        Lately I've been really exploring clip-path, which is a css property I overlooked for a really long time. But its pretty cool and you can do some really neat stuff with it. In the past,
                        I would have resorted to other means not well-suited to achieve similar results, like using svg animations and empty spans or what have you. The only catch is that apparently most browsers - webkit included - seem to have some
                        issues computing background transitions for elements with clip-path and their children, which results in some crazy flicker issues. These go away with filter:blur(0px), for some reason, and its pretty neat to see that it's been a known bug for about a decade.
                        Thanks stack overflow!
                    </p>
                    <p>
                        I've designed language-assistive apps for the speech-impaired, built entire corporate websites from the ground up, wrangled CRMs and whatever else needs doing.
                    </p>
                </div>
            </div>
        );
    }
}

export default About;